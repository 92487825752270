<template>
    <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
            <vx-card>
                <span>
                    <h4 class="mb-5" v-if="this.$route.params.id">Edit List Price</h4>
                    <h4 class="mb-5" v-else>Create Partial List Price</h4>
                </span>
                <div class="vx-row mb-6" style="width:100%;">
                    <vs-button
                        class="ml-4 mt-2"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-arrow-left"
                        @click="handleBack()"
                    >Back</vs-button>
                </div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Code</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="Code" v-model.lazy="data.code" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Name</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="name" :value="data.name" @change="data.name = $event.target.value"/>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('name')"
                            >{{ errors.first('name') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Notes</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <!-- <vs-input class="w-full" name="Notes" :value="data.notes" @change="data.notes = $event.target.value"/> -->
                        <vs-textarea class="w-full" maxlength ="255" name="Notes" :value="data.notes" @change="data.notes = $event.target.value" />
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Model</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <ul class="">
                            <li>
                                <vs-radio name="model" v-model.lazy="data.model" vs-value="Final">Final</vs-radio>
                            </li>
                            <li>
                                <vs-radio name="model" v-model.lazy="data.model" vs-value="Step Ladder">Step Ladder</vs-radio>
                            </li>
                            <span
                                class="text-danger text-sm"
                                v-show="errors.has('model')"
                                >{{ errors.first('model') }}
                            </span>
                        </ul>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid From</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <datepicker :disabled="read" v-validate="'required'" name="valid from" :inline="false" v-model.lazy="data.valid_from" placeholder="Select Date"></datepicker>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('valid from')"
                            >{{ errors.first('valid from') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid To</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <datepicker :disabled="read" v-validate="'required'" name="valid to" :inline="false" v-model.lazy="data.valid_to" placeholder="Select Date"></datepicker>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('valid to')"
                            >{{ errors.first('valid to') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Currency Code</span>
                    </div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <v-select v-validate="'required'" name="currency code" v-model.lazy="data.currency_code" label="Code" placeholder="Select currency code" :options="currency" @input="setSelectedCurrencyCode">
                            <template slot="option" slot-scope="option">
                                <div style="align-items: center;"><span>{{option.Code}}</span></div>
                            </template>
                        </v-select>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('currency code')"
                            >{{ errors.first('currency code') }}
                        </span>
                    </div>
                </div>
                <div class="vx-row mb-6 ml-4 mr-4" v-if="this.$route.params.id == null">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Copy From</span>
					</div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <v-select
                            @input="selectedPriceList"
                            :options="price.copy_value"
                            placeholder="List price"
                            class="input-class"
                        />
                    </div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Items</span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
                        <div class="vx-row mb-2">
                            <div class="vx-col w-2/5" style="display: flex">
                                <span style="margin: auto; width: 5%" class="mr-3" v-if="items[indextr].num != 0">{{items[indextr].num}}.</span>
                                <span style="margin: auto; width: 5%" class="mr-3" v-else></span>
                                <!-- <vs-input label="Item" class="w-full" :value="items[indextr].label" readonly/> -->
                                <span v-if="items[indextr].num != 0" class="w-full" style="margin: auto">
                                    <label v-if="items[indextr].item_unit != 'unit'" class="vs-input--label">{{items[indextr].sku_code}} {{items[indextr].item_unit}}</label>
                                    <label v-else class="vs-input--label">Item</label>
                                    <v-select v-if="items[indextr].item_name == 'item name'" :options="listItems" label="item_name" placeholder="Items" @input="selectedListItems($event, indextr)">
                                        <template slot="option" slot-scope="option">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                    <v-select v-else :options="listItems" label="item_name" placeholder="Items" :value="items[indextr].item_name" @input="selectedListItems($event, indextr)">
                                        <template slot="option" slot-scope="option">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </span>
                                <span v-else class="w-full" style="margin: auto"></span>
                            </div>
                            <div class="vx-col w-2/5" style="display: flex">
                                <div class="vx-col w-1/3 mr-1">
                                    <vs-input @keypress="isNumber($event)" label="Qty From" :value="items[indextr].qty_from" @change="items[indextr].qty_from = $event.target.value" class="w-full" type="number" min="0" name="QtyFrom"/>
                                </div>
                                <div class="vx-col w-1/3 mr-1">
                                    <vs-input @keypress="isNumber($event)" label="Qty To" :value="items[indextr].qty_to" @change="items[indextr].qty_to = $event.target.value" class="w-full" type="number" min="0" name="QtyTo"/>
                                </div>
                                <div class="vx-col w-1/3">
                                    <vx-input-group>
                                        <template slot="append">
                                            <div class="append-text bg-primary" style="margin-top: 19px">
                                                <span>{{data.currency_code}}</span>
                                            </div>
                                        </template>
                                        <vs-input @keypress="isNumber($event)" label="Price" :value="items[indextr].price" @change="items[indextr].price = $event.target.value" class="w-full" name="Price"/>
                                    </vx-input-group>
                                </div>
                            </div>
                            <div class="vx-col w-1/5" style="display: flex">
                                <div class="vx-col w-1/2">
                                    <label class="vs-input--label">Discount</label>
                                    <vs-switch v-model.lazy="items[indextr].discount"/>
                                </div>
                                <div class="vx-col w-1/2" style="text-align: left; margin: auto; margin-bottom: 0">
                                    <vs-button
                                        v-if="items[indextr].status=='minus'"
                                        style="display: table-cell;"
                                        v-on:click="handleRemoveItem(indextr, tr)"
                                        color="danger"
                                        type="filled"
                                        icon-pack="feather"
                                        icon="icon-x"
                                    ></vs-button>
                                    <vs-button
                                        v-else
                                        style="display: table-cell;"
                                        v-on:click="handleAddRow(indextr, tr)"
                                        color="success"
                                        type="filled"
                                        icon-pack="feather"
                                        icon="icon-plus"
                                    ></vs-button>
                                    <vs-button
                                        v-if="items[indextr].status=='plus' && indextr != 0"
                                        class="ml-1"
                                        style="display: table-cell;"
                                        v-on:click="handleRemoveItemParent(indextr)"
                                        color="danger"
                                        type="line"
                                        icon-pack="feather"
                                        icon="icon-x"
                                    ></vs-button>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
                    <vs-button
                        class="ml-auto mr-0"
                        v-on:click="handleAddItem()"
                        color="primary"
                        type="line"
                        line-position="bottom"
                    >+ Add Item</vs-button>
                </div>
                <content-placeholders v-if="statusPlaceholder == true" class="mb-6 ml-4 mr-4">
                    <div class="vx-col sm:w-5/5 w-full mb-2">
                        <div class="vx-row mb-2">
                            <div class="vx-col w-2/5" style="display: flex">
                                <content-placeholders-text style="margin: auto; width: 5%" class="ml-4 mr-3" :lines="1" />
                                <content-placeholders-text class="w-full" :lines="2" />
                            </div>
                            <div class="vx-col w-2/5" style="display: flex">
                                <div class="vx-col w-1/3 mr-1">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <div class="vx-col w-1/3 mr-1">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <div class="vx-col w-1/3">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                            </div>
                            <div class="vx-col w-1/5" style="display: flex">
                                <div class="vx-col w-1/2">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <!-- <div class="vx-col w-1/2" style="text-align: center; margin: auto; margin-bottom: 0">
                                    <content-placeholders-text class="w-full" :lines="3" />
                                </div> -->
                            </div>
                        </div>
                    </div>
                </content-placeholders>
                <!-- <div v-else>Loading Data</div> -->

                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col">
						<vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
					</div>
				</div>
                
            </vx-card>
        </div>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    created() {
        this.getDataListPrice()
        this.getCurrencyCode()
    },
    data() {
        return {
            read: false,
            counter_row: 0,
            counter_item: 1,
            price:{
                copy_id: null,
                copy_name: null,
                copy_value: []
            },
            currency: [],
            items: [],
            listItems: [],
            data: {
                code: "",
                name: "",
                notes: "",
                model: false,
                valid_from: "",
                valid_to: "",
                currency_code: ""
            },
            statusPlaceholder: false,
        };
    },
    methods: {
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        set(v){
            console.log(v)
        },
        handleBack() {
            this.$router.push("/master/pricing-management");
        },
        getCurrencyCode(){
            this.$http
            .get("/api/v1/master/currency").then(resp => {
                if (resp.status == "success") {
                    this.currency = resp.data.records;
                }
            });
        },
        setSelectedCurrencyCode(value) {
            this.data.currency_code = value.Code;
        },
        getCode() {
        this.$vs.loading();
            this.$http
            .get("/api/v1/setting/master-number-code/LP")
            .then((resp) => {
            if (resp.status == "success") {
                this.data.code = resp.data;
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
            });
            console.log(this.data.code)
            if (this.data.code == ""){
                this.$http
                .get("/api/v1/master/price/code")
                .then(resp => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.data.code = resp.data
                        // this.getItemUnit();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                    
                })
                .catch(error => {
                    this.$vs.loading.close();
                    console.log(error);
                });
            }
        },
        getDataListPrice() {
        this.$vs.loading();
        this.$http
            .get("/api/v1/master/prices")
            .then(resp => {
                if (resp.status == "success") {
                    var _this = this
                    resp.data.records.forEach(function(element){
                        _this.price.copy_value.push({
                            id: element.id,
                            label: element.code + " - " + element.name
                        })
                    })

                    this.$vs.loading.close();
                }
            });
        },
        getItemUnit() {
        this.$vs.loading();
            this.$http
            .get("/api/v1/master/item-unit")
            .then(resp => {
                this.$vs.loading.close();
                var _this = this
                if (resp.code == 200) {
                    for(var k = 0; k < resp.data.length; k++){
                        _this.listItems.push({
                            // num: k+1,
                            id: k+1,
                            label: resp.data[k].label,
                            item_name: resp.data[k].item_name,
                            sku_code: resp.data[k].sku_code,
                            item_unit: resp.data[k].item_unit,
                            item_unit_id: resp.data[k].item_unit_id,
                            // qty_from: "0",
                            // qty_to: "0",
                            // price: "0",
                            // discount: false,
                            // status: "plus"
                        })
                    }
                    // if(_this.items.length == resp.data.length){
                    //     _this.statusPlaceholder = false
                    // }
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        selectedListItems(val, index){
            if(val != null){
                this.items[index].sku_code = val.sku_code;
                this.items[index].item_unit = val.item_unit;
                this.items[index].item_unit_id = val.item_unit_id;
                this.items[index].item_name = val.item_name;
                this.items[index].label = val.label;

                var i;
                for (i = 1; i <= this.items[index].child; i++) {
                    this.items[index+i].sku_code = val.sku_code;
                    this.items[index+i].item_unit = val.item_unit;
                    this.items[index+i].item_unit_id = val.item_unit_id;
                    this.items[index+i].item_name = val.item_name;
                    this.items[index+i].label = val.label;
                } 
            }
        },
        handleRemoveItem(indextr, tr){
            this.items.splice(indextr, 1)
            this.items[tr.parent].child -= 1
        },
        handleRemoveItemParent(indextr){
            var i;
            for (i = 1; i <= this.items[indextr].child; i++) {
                this.items.splice(indextr+1, 1)
            }
            this.items.splice(indextr, 1)

            var a = 1
            for (i = 0; i < this.items.length; i++) {
                if(this.items[i].num != 0){
                    this.items[i].num = a
                    a++
                }
            }

            this.counter_item -= 1
        },
        handleAddRow(indextr, tr){
            var counter_row = this.items[indextr].child + 1
            this.items.splice(indextr+1, 0, {
                num: 0,
                label: tr.label,
                item_name: tr.item_name,
                sku_code: tr.sku_code,
                item_unit: tr.item_unit,
                qty_from: "0",
                qty_to: "0",
                item_unit_id: 0,
                price: "0",
                discount: false,
                status: "minus",
                parent: indextr
            })
            this.items[indextr].child = counter_row
        },
        handleAddItem(){
            this.counter_item += 1
            this.items.push({
                num: this.counter_item,
                label: "label",
                item_name: "item name",
                sku_code: "sku code",
                item_unit: "unit",
                qty_from: "0",
                qty_to: "0",
                item_unit_id: 0,
                price: "0",
                discount: false,
                status: "plus",
                child: 0
            })
        },
        selectedPriceList(val){
            if(val == null){
                this.items = []
                this.showItem()
            } else {
                this.$http
                .get("/api/v1/master/price/" + val.id)
                .then(resp => {
                    this.items = []
                    var _this = this
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.statusPlaceholder = true
                        
                        setTimeout(function(){
                            for(var k = 0; k < resp.data.list.length; k++){
                                _this.items.push({
                                    num: resp.data.list[k].num,
                                    label: resp.data.list[k].label,
                                    status: resp.data.list[k].status,
                                    qty_from: resp.data.list[k].qty_from.toString(),
                                    qty_to: resp.data.list[k].qty_to.toString(),
                                    price: resp.data.list[k].price.toString(),
                                    discount: resp.data.list[k].discount,
                                    sku_code: resp.data.list[k].sku_code,
                                    item_unit: resp.data.list[k].item_unit,
                                    item_name: resp.data.list[k].item_name,
                                })
                            }
                            _this.statusPlaceholder = false
                            // if(_this.items.length == resp.data.list.length){
                            //     _this.statusPlaceholder = false
                            //     _this.getNewItem(val.id)
                            // }
                        }, 0);
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                })
                .catch(error => {
                    this.$vs.loading.close();
                    console.log(error);
                });
            }
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if(this.data.name == ""){
                    this.errors.add({
                        field: "name",
                        msg: "The name is required"
                    });
                    result = false;
                }
                if(this.data.model == false){
                    this.errors.add({
                        field: "model",
                        msg: "The model is required"
                    });
                    result = false;
                }
                if (result) {
                    this.$vs.loading();
                    var _this = this
                    this.items.forEach(function(element, index){
                        _this.items[index].qty_from = element.qty_from.toString()
                        _this.items[index].qty_to = element.qty_to.toString()
                        _this.items[index].price = element.price.toString()
                        _this.items[index].item_unit_id = element.item_unit_id
                    })
                    if (this.$route.params.id) {
                        console.log(this.paramData())
                        this.putData();
                    } else {
                        this.postData();
                        console.log(this.paramData())
                    }
                }
            });
        },
        paramData() {
            return {
                price_id: parseInt(this.$route.params.id),
                code: this.data.code,
                name: this.data.name,
                notes: this.data.notes,
                is_partial: 1,
                model: this.data.model,
                valid_from: this.data.valid_from,
                valid_to: this.data.valid_to,
                currency_code: this.data.currency_code,
                item: this.items
            };
        },
        postData() {
            this.$http
                .post("/api/v1/master/price", this.paramData())
                .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.handleClose();
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "New List Price Created",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                    this.$router.push("/master/pricing-management");
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        putData() {
        this.$http
            .put("/api/v1/master/price/" + this.$route.params.id, this.paramData())
            .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "List Price Updated",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        getData() {
        this.$vs.loading();
            this.$http
            .get("/api/v1/master/price/" + this.$route.params.id)
            .then(resp => {
                var _this = this
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.data.code = resp.data.code;
                    this.data.name = resp.data.name;
                    this.data.notes = resp.data.notes;
                    this.data.model = resp.data.model;
                    this.data.valid_from = resp.data.valid_from;
                    this.data.valid_to = resp.data.valid_to;
                    this.data.currency_code = resp.data.currency_code;
                    this.read = (resp.data.price_rule_usage > 0) ? true : false;

                    this.statusPlaceholder = true
                    var a = 0
                    setTimeout(function(){
                        for(var k = 0; k < resp.data.list.length; k++){
                            _this.items.push({
                                num: resp.data.list[k].num,
                                label: resp.data.list[k].label,
                                status: resp.data.list[k].status,
                                qty_from: resp.data.list[k].qty_from.toString(),
                                qty_to: resp.data.list[k].qty_to.toString(),
                                price: resp.data.list[k].price.toString(),
                                discount: resp.data.list[k].discount,
                                sku_code: resp.data.list[k].sku_code,
                                item_unit: resp.data.list[k].item_unit,
                                item_unit_id: resp.data.list[k].item_unit_id,
                                item_name: resp.data.list[k].item_name,
                            })
                            if(resp.data.list[k].num != 0){
                                a = k
                                _this.items[k].child = 0
                                if(k != 0){
                                    _this.counter_item += 1
                                }
                            } else {
                                _this.items[a].child += 1
                                _this.items[k].parent = a
                            }
                        }
                        _this.statusPlaceholder = false
                        // if(_this.items.length == resp.data.list.length){
                        //     _this.statusPlaceholder = false
                        //     _this.getNewItem(_this.$route.params.id)
                        // }
                    }, 0); 

                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        // getNewItem(val) {
        //     this.$http
        //     .get("/api/v1/master/new-item-unit/" + val)
        //     .then(resp => {
        //         var _this = this
        //         this.$vs.loading.close();
        //         if (resp.code == 200) {
                    
        //             setTimeout(function(){
        //                 var x = 0
        //                 _this.items.forEach(function(element){
        //                     if(element.num != 0){
        //                         x++
        //                     }
        //                 })
        //                 for(var k = 0; k < resp.data.length; k++){
        //                     _this.items.push({
        //                         num: x+k+1,
        //                         label: resp.data[k].label,
        //                         item_name: resp.data[k].item_name,
        //                         sku_code: resp.data[k].sku_code,
        //                         item_unit: resp.data[k].item_unit,
        //                         qty_from: "0",
        //                         qty_to: "0",
        //                         price: "0",
        //                         discount: false,
        //                         status: "plus"
        //                     })
                            
        //                 }
        //                 if(_this.items.length == resp.data.length){
        //                     _this.statusPlaceholder = false
        //                 }
        //             }, 0); 

        //         } else {
        //             this.$vs.notify({
        //                 color: "danger",
        //                 title: "Error",
        //                 text: resp.message,
        //                 position: "top-right",
        //                 iconPack: "feather",
        //                 icon: "icon-x-circle"
        //             });
        //         }
        //     })
        //     .catch(error => {
        //         this.$vs.loading.close();
        //         console.log(error);
        //     });
        // },
        // cit() {
        // this.$vs.loading();
        //     this.$http
        //     .get("/api/v1/master/item-unit")
        //     .then(resp => {
        //         this.$vs.loading.close();
        //         var _this = this
        //         if (resp.code == 200) {
        //             for(var k = 0; k < resp.data.length; k++){
        //                 var unit = resp.data[k].item_unit;
        //                 var p = []
        //                 if(unit == 'Pcs'){
        //                     p = [8000, 7000, 6000]
        //                 } else if(unit == 'Bal') {
        //                     p = [40000, 39000, 38000]
        //                 } else if(unit == 'Carton') {
        //                     p = [250000, 240000, 230000]
        //                 } else if(unit == 'Dus 1000') {
        //                     p = [350000, 340000, 330000]
        //                 } else if(unit == 'Pack 50') {
        //                     p = [50000, 49000, 48000]
        //                 } else if(unit == 'Bag 120') {
        //                     p = [150000, 140000, 130000]
        //                 } else if(unit == 'Bag 20') {
        //                     p = [70000, 69000, 67000]
        //                 } else if(unit == 'Bal 50') {
        //                     p = [90000, 89000, 86000]
        //                 } else if(unit == 'Pack 10') {
        //                     p = [20000, 19000, 18000]
        //                 } else if(unit == 'Dus 40') {
        //                     p = [50000, 49000, 47000]
        //                 } else if(unit == 'Pack 5') {
        //                     p = [10000, 9000, 8000]
        //                 } else if(unit == 'Dus 72') {
        //                     p = [120000, 119000, 118000]
        //                 } else if(unit == 'Dus 12') {
        //                     p = [34000, 33000, 32000]
        //                 } else if(unit == 'Bag') {
        //                     p = [9000, 8000, 7000]
        //                 } else if(unit == 'Dus 20') {
        //                     p = [54000, 53000, 51000]
        //                 } else if(unit == 'Box') {
        //                     p = [200000, 190000, 180000]
        //                 } else {
        //                     p = [0, 0, 0]
        //                 }
                        
        //                 var qtyf = [1, 1001, 50001]
        //                 var qtyt = [1000, 50000, 999999999]
        //                 var status = ['plus', 'minus', 'minus']
        //                 var num = [(k+1), '', '']

        //                 for(var i = 0; i < 3; i++){
        //                     _this.items.push({
        //                         num: num[i],
        //                         label: resp.data[k].label,
        //                         item_name: resp.data[k].item_name,
        //                         sku_code: resp.data[k].sku_code,
        //                         item_unit: resp.data[k].item_unit,
        //                         qty_from: qtyf[i],
        //                         qty_to: qtyt[i],
        //                         price: p[i],
        //                         discount: true,
        //                         status: status[i]
        //                     })
        //                 }
        //             }
        //             _this.statusPlaceholder = false
        //         } else {
        //             this.$vs.notify({
        //                 color: "danger",
        //                 title: "Error",
        //                 text: resp.message,
        //                 position: "top-right",
        //                 iconPack: "feather",
        //                 icon: "icon-x-circle"
        //             });
        //         }
        //     })
        //     .catch(error => {
        //         this.$vs.loading.close();
        //         console.log(error);
        //     });
        // },
        showItem(){
            this.items.push({
                num: 1,
                label: "label",
                item_name: "item name",
                sku_code: "sku code",
                item_unit: "unit",
                qty_from: "0",
                qty_to: "0",
                price: "0",
                discount: false,
                status: "plus",
                child: 0
            })
        }
    },
    mounted() {
        if(!(this.$route.params.id)){
            if (this.$route.query.cit){
                this.getCode();
                this.cit()
            } else {
                this.getCode();
                
                this.showItem()
            }   

            // this.getCode();
            // this.getItemUnit();
        } else {
            this.getData();
        }
        this.getItemUnit();
    },
    computed: {},
    watch: {
        "data.name": function(val) {
            if(val == ""){
                this.errors.add({
                    field: "name",
                    msg: "The name is required"
                });
            } else {
                this.errors.clear()
            }
        },
        "data.model": function(val) {
            if(val == false){
                this.errors.add({
                    field: "model",
                    msg: "The model is required"
                });
            } else {
                this.errors.clear()
            }
        },
    }
};
</script>
<style>
    .vs__search{
        line-height: 1.8
    }
</style>